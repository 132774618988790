import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
// Pages imports
import Faq from "js/pages/Faq";
import MiReporteCLN from "js/pages/MiReporteCLN";
import Privacidad from "js/pages/Privacidad";
import { Formulario, Trivia, Encuesta } from "js/pages/Accion";
import {
  CrearReconocimiento,
  TablaReconocimientos,
  Reconocido,
  ReconocidoAprobado,
  ReconocidoRechazado,
  AprobacionReconocimiento,
  RechazoReconocimiento,
  EditarReconocimiento,
  ReconocidoEditado,
  ReconocimientosAprobados
} from "js/pages/Reconocimiento";
import AccionYaParticipo from "js/pages/Accion/YaParticipo";
import DetalleAccion from "js/pages/DetalleAccion";
import RankingAccion from "js/pages/RankingAccion";
import Condiciones from "js/pages/Condiciones";
import Informacion from "js/pages/Informacion";
import Privado from "js/pages/Privado";
import Landing from "js/pages/Landing";
import Profile from "js/pages/Profile";
import UpdatePassword from "js/pages/UpdatePassword";
import Movimientos from "js/pages/Movimientos";
import SesionesTracking from "js/pages/SesionesTracking";
import CatalogoUI from "js/pages/Catalogo";
import ProductoRedux from "js/pages/ProductoRedux";
import Pagina from "js/pages/Pagina";
import {
  Template,
  Layout,
  Landing as LandingEditor,
  Destacados
} from "js/pages/TemplateBuilder2";
import { Canje } from "js/pages/Canje";

// Admin pages
import AdminDashboard from "js/pages/Admin/Dashboard";
import AdminProducto from "js/pages/Admin/Producto";
import AdminPrograma from "js/pages/Admin/Programa";
import AdminEmails from "js/pages/Emails";
import EmailEdit from "js/pages/Emails/Edit";
import ProgramaEdit from "js/pages/Admin/Programa/Edit";
import LinksTrackingEdit from "js/pages/Admin/Programa/LinksTracking/Edit";
import ProgramaGestion from "js/pages/Admin/Programa/Gestion";
import ProgramaLegales from "js/pages/Admin/Programa/Legales";
import ProgramaPerfil from "js/pages/Admin/Programa/Perfil";
import ProgramaSegmentos from "js/pages/Admin/Programa/Segmentos";
import ProgramaRoles from "js/pages/Admin/Programa/Roles";
import ProgramaRolesEdit from "js/pages/Admin/Programa/Roles/Edit";
import ProgramaLog from "js/pages/Admin/Log";
import ProgramaLogView from "js/pages/Admin/Log/View";
import ProgramaNotificaciones from "js/pages/Admin/Programa/Notificaciones";
import ProgramaNotificacionesEdit from "js/pages/Admin/Programa/Notificaciones/Edit";
import ProgramaCatalogo from "js/pages/Admin/Programa/Catalogo";
import ProgramaCatalogoEdit from "js/pages/Admin/Programa/Catalogo/Edit";
import ProgramaGenerarEleccionProducto from "js/pages/Admin/Programa/EleccionProducto";
import ProgramaCuentaCorriente from "js/pages/Admin/Programa/CuentaCorriente";
import ProgramaCuentaCorrienteEdit from "js/pages/Admin/Programa/CuentaCorriente/Edit";
import AdminProveedor from "js/pages/Admin/Proveedor";
import AdminReportesCLN from "js/pages/Admin/ReportesCLN";
import AdminGestionReportesCLN from "js/pages/Admin/ReportesCLN/Gestion";
import ReportesCLN from "js/pages/ReportesCLN";
import GestionReportesCLN from "js/pages/ReportesCLN/Gestion";
import ReportsTable from "js/pages/Admin/Reportes/Table";
import ReportsDetail from "js/pages/Admin/Reportes/Detail";
import ContenidosTable from "js/pages/Contenidos/Table";
import ContenidosDetail from "js/pages/Contenidos/Detail";
import ProductoEdit from "js/pages/Admin/Producto/Edit";
import ProveedorEdit from "js/pages/Admin/Proveedor/Edit";
import ProveedorSucursal from "js/pages/Admin/Proveedor/Sucursal";
import ProveedorSucursalEdit from "js/pages/Admin/Proveedor/Sucursal/Edit";
import ProductoSucursal from "js/pages/Admin/Producto/Sucursal";
import ProductoSucursalEdit from "js/pages/Admin/Producto/Sucursal/Edit";
import AdminAccion from "js/pages/Admin/Accion";
import AdminAccionEdit from "js/pages/Admin/Accion/Edit";
import AdminFormularioJerarquiaEdit from "js/pages/Admin/Programa/Jerarquia";
import AdminFormularioReconocimientoEdit from "js/pages/Admin/Programa/FormularioReconocimiento";
import AdminUsuario from "js/pages/Admin/Usuario";
import AdminUsuarioEdit from "js/pages/Admin/Usuario/Edit";
import AdminUsuarioPerfilEdit from "js/pages/Admin/Usuario/Edit/EditPerfil.js";
import AdminCuentaCorriente from "js/pages/Admin/CuentaCorriente";
import AdminCanje from "js/pages/Admin/Canje";
import Canjes from "js/pages/Canjes";
import AdminCanjeDetail from "js/pages/Admin/Canje/AdminCanjeDetail";
import AdminLotes from "js/pages/Admin/Lotes";
import AdminLotesDetail from "js/pages/Admin/Lotes/AdminLotesDetail";
import AdminFacturas from "js/pages/Admin/Facturas";
import AdminFacturasNew from "js/pages/Admin/Facturas/New";
import AdminFacturasDetail from "js/pages/Admin/Facturas/AdminFacturasDetail";
import AdminUsuariosCuentaCorriente from "js/pages/Admin/UsuariosCuentaCorriente";
import AdminNewUsuariosCuentaCorriente from "js/pages/Admin/UsuariosCuentaCorriente/New";
import UsuariosCuentaCorriente from "js/pages/UsuariosCuentaCorriente";
import NewUsuariosCuentaCorriente from "js/pages/UsuariosCuentaCorriente/New";
import AdminIdioma from "js/pages/Admin/Idiomas";
import AdminIdiomaEdit from "js/pages/Admin/Idiomas/Edit";
import AdminIdiomaNew from "js/pages/Admin/Idiomas/New";
import Idioma from "js/pages/Admin/Programa/Idioma";
import ProgramaIdiomaEdit from "js/pages/Admin/Programa/Idioma/Edit";

// Ventas Retail
import AdminProductoRetail from "js/pages/Admin/Programa/ProductoRetail";
import AdminProductoRetailEdit from "js/pages/Admin/Programa/ProductoRetail/Edit";
import AdminCanalRetail from "js/pages/Admin/Programa/CanalRetail";
import AdminCanalRetailEdit from "js/pages/Admin/Programa/CanalRetail/Edit";
import AdminVentaRetail from "js/pages/Admin/Programa/VentaRetail";
import ProgramaVentaRetail from "js/pages/VentaRetail";
import ProgramaVentaRetailEdit from "js/pages/VentaRetail/Edit";
import AdminClienteRetail from "js/pages/Admin/Programa/ClienteRetail";
import AdminClienteRetailEdit from "js/pages/Admin/Programa/ClienteRetail/Edit";

// Productos Codificados
import AdminProductoCodificado from "js/pages/Admin/Programa/ProductoCodificado";
import AdminProductoCodificadoEdit from "js/pages/Admin/Programa/ProductoCodificado/Edit";
import ProgramaVentaProductoCodificado from "js/pages/VentaProductoCodificado";
import ProgramaVentaProductoCodificadoEdit from "js/pages/VentaProductoCodificado/Edit";
import AdminVentaProductoCodificado from "js/pages/Admin/Programa/VentaProductoCodificado";

// Moderación de Cargas de Facturas
import AdminModeracion from "js/pages/Admin/Programa/Moderacion";

// Moderación Factura por puntos
import AdminModeracionFacturaPorPuntos from "js/pages/Admin/Programa/ModeracionFacturaPorPuntos";

// Carga de Facturas
import AdminCargaFacturas from "js/pages/Admin/Programa/CargaFacturas";
import ProgramaCargaFacturas from "js/pages/CargaFacturas"; // LADO USUARIO

// Factura por puntos
import AdminFacturaPorPuntos from "js/pages/Admin/Programa/FacturaPorPuntos";
import ProgramaFacturaPorPuntos from "js/pages/FacturaPorPuntos"; // LADO USUARIO
import AdminFacturaPorPuntosParametros from "js/components/FormularioFacturaPorPuntos/Parametros";

// Canje Terceros
import AdminCanjeTerceros from "js/pages/Admin/CanjeTerceros";

// Carga de Codigos
import AdminCargaDeCodigos from "js/pages/Admin/Programa/CargadeCodigos"; // LADO BACK OFFICE
import AdminVentaCodigoCargado from "js/pages/Admin/Programa/VentaCodigoCargado"; // LADO BACK OFFICE
import AdminCargaDeCodigosParametrosBanner from "js/pages/Admin/Programa/CargadeCodigos/Parametros";

import AdminCatalogoCodigos from "js/pages/Admin/Programa/CatalogoCodigos";
import AdminCatalogoCodigosEdit from "js/pages/Admin/Programa/CatalogoCodigos/Edit";
import AdminSucursalesCatalogoCodigos from "js/pages/Admin/Programa/CatalogoCodigos/SucursalBeneficio";
import AdminSucursalCatalogoCodigosEdit from "js/pages/Admin/Programa/CatalogoCodigos/SucursalBeneficio/Edit";
import AdminSolicitudesCatalogoCodigos from "js/pages/Admin/Programa/CatalogoCodigos/SolicitudBeneficio";
import ProgramaCatalogoCargaCodigos from "js/pages/CargaCodigosCatalogo";
import ProgramaCargaCodigosBeneficios from "js/pages/CargaCodigosBeneficios";
import { Solicitudes } from "js/pages/CargaCodigosSolicitud";

import ProgramaCargadeCodigosEdit from "js/pages/CargadeCodigos/Edit"; // LADO USUARIO
import ProgramaCargadeCodigos from "js/pages/CargadeCodigos"; // LADO USUARIO

// Beneficios con cupón
import AdminBeneficio from "js/pages/Admin/Programa/Beneficio";
import AdminBeneficioEdit from "js/pages/Admin/Programa/Beneficio/Edit";
import AdminSucursalesBeneficio from "js/pages/Admin/Programa/Beneficio/SucursalBeneficio";
import AdminSucursalBeneficioEdit from "js/pages/Admin/Programa/Beneficio/SucursalBeneficio/Edit";
import AdminSolicitudesBeneficios from "js/pages/Admin/Programa/Beneficio/SolicitudBeneficio";
import ProgramaCatalogoBeneficio from "js/pages/BeneficioCatalogo";
import ProgramaBeneficio from "js/pages/Beneficio";
import { Solicitud } from "js/pages/BeneficioSolicitud";

// Unilever
import UnileverAdminCampana from "js/pages/Unilever/Campana";
import UnileverAdminCampanaEdit from "js/pages/Unilever/Campana/Edit";
import UnileverAdminTargets from "js/pages/Unilever/Campana/Targets";
import UnileverAdminGruposSKU from "js/pages/Unilever/Campana/GruposSKU";
import UsuarioUnilever from "js/pages/Unilever/Usuario";
import UsuarioEditUnilever from "js/pages/Unilever/Usuario/Edit";
import UnileverMed from "js/pages/Unilever/Med";
import UnileverMedEdit from "js/pages/Unilever/Med/Edit";
import UnileverMedVariables from "js/pages/Unilever/Med/VariablesMed";
import UnileverMedVariablesEdit from "js/pages/Unilever/Med/VariablesMed/Edit";
import UnileverMedPreventistas from "js/pages/Unilever/Med/Preventistas";
import UnileverNotificaciones from "js/pages/Unilever/Med/Notificaciones";
import UnileverNotificacionesEdit from "js/pages/Unilever/Med/Notificaciones/Edit";
import UnileverCampanasMeds from "js/pages/Unilever/CampanasSupervisor";
import UnileverCampanaTargets from "js/pages/Unilever/CampanasSupervisor/TargetsCampana";
import UnileverTargets from "js/pages/Unilever/Targets";
import UnileverPerformance from "js/pages/Unilever/Performance";
import UnileverPerformanceEDD from "js/pages/Unilever/PerformanceEDD";

// Campañas genericas
import AdminCampanas from "js/pages/Admin/Programa/Campana";
import AdminCampanaEdit from "js/pages/Admin/Programa/Campana/Edit";
import AdminCampanaObjetivos from "js/pages/Admin/Programa/Campana/Objetivos";
import AdminCampanasParametros from "js/pages/Admin/Programa/Campana/Parametros";
import AdminCampanasTargets from "js/pages/Admin/Programa/Campana/Targets";
import AdminCampanasGruposProductos from "js/pages/Admin/Programa/Campana/GruposProductos";

import PerformanceCampanas from "js/pages/PerformanceCampanas";

// Links Tracking
import AdminLinksTracking from "js/pages/Admin/Programa/LinksTracking";

// Sesiones Tracking
import AdminSesionesTracking from "js/pages/Admin/Programa/SesionesTracking";

// Api Tokens
import AdminApiToken from "js/pages/Admin/Programa/ApiToken";
import AdminApiTokenEdit from "js/pages/Admin/Programa/ApiToken/Edit";
// Programas Terceros
import AdminProgramasTerceros from "js/pages/Admin/Programa/ApiToken/ProgramasTerceros/Tabla";
import AdminProgramasTercerosEdit from "js/pages/Admin/Programa/ApiToken/ProgramasTerceros/Edit";

import Error from "js/pages/Error";

// Layous imports
import Layouts from "js/layouts";
import Access from "js/layouts/Access";

import { USER_ROLES as Roles } from "js/models/User";
import AdminRoles from "js/hoc/Authenticate";

import AuthService from "js/utils/AuthService";
import { getSSOUrl } from "js/utils";
import { canShowThis } from "js/App/utils";
import {
  Signin,
  RestorePassword,
  RestoreUser,
  Signup,
  EmailVerificado
} from "../components/Access";

const Routes = ({
  error = false,
  programa,
  isLoggedIn,
  isAllowedToNavigate
}) => {
  const isLogged = AuthService.loggedIn(isLoggedIn);
  const { LayoutBO, LayoutUI } = Layouts;

  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/landing" />
        <Route
          exact
          path="/admin"
          render={() => {
            switch (true) {
              case error === true:
                return (
                  <Redirect to={{ pathname: `/error`, state: { error } }} />
                );
              case !isLogged:
                AuthService.setUltimaURL("/admin/dashboard");
                return <Redirect to="/signin" />;
              default:
                return <Redirect to="/admin/dashboard" />;
            }
          }}
        />
        <Route
          exact
          path={"/sso/metadata.xml"}
          render={() => {
            window.location.href = getSSOUrl();
            return null;
          }}
        />
        <Route
          exact
          path="/proximamente"
          render={() => {
            return (
              <iframe
                title="coming-soon"
                src={AuthService.getHtmlExpectativa()}
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                  margin: 0,
                  padding: 0,
                  overflow: "hidden",
                  "z-index": 999999
                }}
              />
            );
          }}
        />
        <LayoutUI track exact path="/profile" component={Profile} />
        {canShowThis(programa.idPrograma) && (
          <LayoutUI
            track
            exact
            path="/updateMyPassword"
            component={UpdatePassword}
          />
        )}
        <LayoutUI
          track
          exact
          path="/mis-movimientos"
          permisos={[Roles.MIS_DATOS_MOVIMIENTOS_R]}
          component={AdminRoles(Movimientos, [Roles.MIS_DATOS_MOVIMIENTOS_R])}
        />
        <LayoutUI
          track
          exact
          path="/sesiones-tracking"
          permisos={[Roles.SESSION_TRACKING_R]}
          component={AdminRoles(SesionesTracking, [Roles.SESSION_TRACKING_R])}
        />
        <LayoutUI track exact path="/landing" component={Landing} />
        <LayoutUI track exact path="/catalogo" component={CatalogoUI} />
        <LayoutUI
          track
          path="/producto/:idEleccionProducto"
          component={ProductoRedux}
        />
        <LayoutUI track exact path="/page/:pagina" component={Pagina} />
        <LayoutUI track exact path="/canje/:idCanje" component={Canje} />
        <LayoutUI track exact path="/faq" component={Faq} />
        <LayoutUI
          track
          exact
          path="/reportes"
          permisos={[Roles.CLN_MI_REPORTE_R]}
          component={AdminRoles(MiReporteCLN, [Roles.CLN_MI_REPORTE_R])}
        />
        <LayoutUI
          track
          exact
          path="/reportesCLN"
          permisos={[Roles.CLN_MI_REPORTE_R]}
          component={AdminRoles(ReportesCLN, [Roles.CLN_REPORTE_R])}
        />
        <LayoutUI
          track
          exact
          path={`/reportesCLN/gestion`}
          permisos={[Roles.CLN_REPORTE_W]}
          component={AdminRoles(GestionReportesCLN, [Roles.CLN_REPORTE_W])}
        />
        <LayoutUI
          track
          exact
          path="/contenidos"
          permisos={[Roles.CONTENIDOS_R]}
          component={AdminRoles(ContenidosTable, [Roles.CONTENIDOS_R])}
        />
        <LayoutUI
          track
          exact
          path="/contenidos/:tipoContenido"
          component={ContenidosDetail}
        />
        <LayoutUI
          track
          exact
          path="/misreportes"
          permisos={[Roles.REPORTE_R]}
          component={AdminRoles(ReportsTable, [Roles.REPORTE_R])}
        />
        <LayoutUI
          track
          exact
          path="/misreportes/:idReport"
          permisos={[Roles.REPORTE_R]}
          component={AdminRoles(ReportsDetail, [Roles.REPORTE_R])}
        />
        <LayoutUI
          track
          exact
          path="/mi-performance"
          permisos={[]}
          component={UnileverPerformance}
        />
        <LayoutUI track exact path="/privacidad" component={Privacidad} />
        <LayoutUI track exact path="/condiciones" component={Condiciones} />
        <LayoutUI track exact path="/informacion" component={Informacion} />
        <LayoutUI track exact path="/privado" component={Privado} />
        <LayoutUI
          track
          exact
          path="/formularioreconocimiento"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.CREAR_RECONOCIMIENTO_W
          ]}
          component={AdminRoles(CrearReconocimiento, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.CREAR_RECONOCIMIENTO_W
          ])}
        />
        <LayoutUI
          track
          exact
          path="/formularioreconocimiento/:idReconocimiento"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.CREAR_RECONOCIMIENTO_W
          ]}
          component={AdminRoles(CrearReconocimiento, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.CREAR_RECONOCIMIENTO_W
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocido/aprobados"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.VER_RECONOCIMIENTOS_APROBADOS_R
          ]}
          component={AdminRoles(ReconocimientosAprobados, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.VER_RECONOCIMIENTOS_APROBADOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocido/:idReconocimiento/aprobacion"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ]}
          component={AdminRoles(AprobacionReconocimiento, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocido/:idReconocimiento/aprobado"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ]}
          component={AdminRoles(ReconocidoAprobado, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocido/:idReconocimiento/rechazo"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ]}
          component={AdminRoles(RechazoReconocimiento, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocido/:idReconocimiento/rechazado"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ]}
          component={AdminRoles(ReconocidoRechazado, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocido/:idReconocimiento/edicion"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ]}
          component={AdminRoles(EditarReconocimiento, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocido/:idReconocimiento/editado"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ]}
          component={AdminRoles(ReconocidoEditado, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.APROBAR_RECONOCIMIENTO_W,
            Roles.VER_RECONOCIMIENTOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocido/:idReconocimiento"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.CREAR_RECONOCIMIENTO_W
          ]}
          component={AdminRoles(Reconocido, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.CREAR_RECONOCIMIENTO_W
          ])}
        />
        <LayoutUI
          track
          exact
          path="/reconocimientos"
          permisos={[
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.VER_RECONOCIMIENTOS_R
          ]}
          component={AdminRoles(TablaReconocimientos, [
            Roles.FORMULARIO_RECONOCIMIENTO_R,
            Roles.VER_RECONOCIMIENTOS_R
          ])}
        />
        <LayoutUI track exact path="/trivia/:idAccion" component={Trivia} />
        <LayoutUI track exact path="/encuesta/:idAccion" component={Encuesta} />
        <LayoutUI
          track
          exact
          path="/formulario/:idAccion"
          component={Formulario}
        />
        <LayoutUI
          track
          exact
          path="/ranking/:idAccion"
          component={RankingAccion}
        />
        <LayoutUI
          track
          exact
          path="/detalleAccion/:idAccion"
          component={DetalleAccion}
        />
        <LayoutUI
          track
          exact
          path={`/cc-usuarios/new`}
          component={AdminRoles(NewUsuariosCuentaCorriente, [
            Roles.ASIGNAR_PUNTOS_USUARIO_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/cc-usuarios/usuario/:idUsuario`}
          component={AdminRoles(UsuariosCuentaCorriente, [
            Roles.USUARIO_R,
            Roles.MOVIMIENTO_USUARIO_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/cc-usuarios`}
          component={AdminRoles(UsuariosCuentaCorriente, [
            Roles.MOVIMIENTO_USUARIO_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/usuarios/:idUsuario/:edit`}
          component={AdminRoles(AdminUsuarioEdit, [Roles.USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/usuarios/:idUsuario/edit/perfil`}
          component={AdminRoles(AdminUsuarioPerfilEdit, [Roles.USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/usuarios/jerarquia`}
          component={AdminRoles(AdminFormularioJerarquiaEdit, [
            Roles.JERARQUIA_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/usuarios/:idUsuario`}
          component={AdminRoles(AdminUsuarioEdit, [Roles.USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/usuarios`}
          component={AdminRoles(AdminUsuario, [Roles.USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/usuarios-mis-ventas/:idUsuario/:edit`}
          component={AdminRoles(UsuarioEditUnilever, [Roles.USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/usuarios-mis-ventas/:idUsuario`}
          component={AdminRoles(UsuarioEditUnilever, [Roles.USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/usuarios-mis-ventas`}
          component={AdminRoles(UsuarioUnilever, [Roles.USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/canjes`}
          component={AdminRoles(Canjes, [Roles.CANJE_R])}
        />
        <LayoutUI
          track
          exact
          path={`/canjes/:idCanje`}
          component={AdminRoles(AdminCanjeDetail, [
            Roles.CANJE_R,
            Roles.CANJE_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/canjes/:idCanje/:view`}
          component={AdminRoles(AdminCanjeDetail, [Roles.CANJE_R])}
        />
        <LayoutUI
          track
          exact
          path={`/ventas`}
          component={AdminRoles(ProgramaVentaRetail, [Roles.VENTA_RETAIL_R])}
        />
        <LayoutUI
          track
          exact
          path={`/ventas/:idVenta`}
          component={AdminRoles(ProgramaVentaRetailEdit, [
            Roles.VENTA_RETAIL_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/ventas/:idVenta/view`}
          component={AdminRoles(ProgramaVentaRetailEdit, [
            Roles.VENTA_RETAIL_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/mis-ventas`}
          component={AdminRoles(ProgramaVentaRetail, [Roles.MIS_VENTA_RETAIL])}
        />
        <LayoutUI
          track
          exact
          path={`/mis-ventas/:idVenta`}
          component={AdminRoles(ProgramaVentaRetailEdit, [
            Roles.VENTA_RETAIL_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/mis-ventas/:idVenta/view`}
          component={AdminRoles(ProgramaVentaRetailEdit, [
            Roles.MIS_VENTA_RETAIL
          ])}
        />
        <LayoutUI
          track
          exact
          path="/carga-de-codigos/new"
          component={AdminRoles(ProgramaCargadeCodigosEdit, [
            Roles.CARGACODIGOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/carga-de-codigos`}
          component={AdminRoles(ProgramaCargadeCodigos, [
            Roles.VENTA_CARGA_CODIGOS_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/carga-facturas`}
          component={AdminRoles(ProgramaCargaFacturas, [Roles.FACTURAS_R])}
        />
        {/* FACTURA POR PUNTOS */}
        <LayoutUI
          track
          exact
          path={`/factura-por-puntos`}
          component={AdminRoles(ProgramaFacturaPorPuntos, [
            Roles.FACTURAS_POR_PUNTOS_R
          ])}
        />
        {/* CATALOGO CODIGOS LADO USUARIO */}
        <LayoutUI
          track
          exact
          path={`/solicitudes-catalogo-codigos`}
          component={AdminSolicitudesCatalogoCodigos}
        />
        <LayoutUI
          track
          exact
          path={`/mis-solicitudes`}
          component={AdminSolicitudesCatalogoCodigos}
        />
        <LayoutUI
          track
          exact
          path={`/catalogo-codigos`}
          component={ProgramaCatalogoCargaCodigos}
        />
        <LayoutUI
          track
          exact
          path={`/beneficio-catalogo-codigos/:idBeneficio`}
          component={ProgramaCargaCodigosBeneficios}
        />
        <LayoutUI
          track
          exact
          path={`/solicitud-catalogo-codigos/:idSolicitud`}
          component={Solicitudes}
        />
        <LayoutUI
          track
          exact
          path={`/mi-solicitud/:idSolicitud`}
          component={Solicitudes}
        />
        <LayoutUI
          track
          exact
          path={`/registrar-productos`}
          component={AdminRoles(ProgramaVentaProductoCodificado, [
            Roles.VENTA_PRODUCTO_CODIFICADO_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/registrar-productos/:idVenta`}
          component={AdminRoles(ProgramaVentaProductoCodificadoEdit, [
            Roles.PROGRAMA_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/registrar-mis-productos`}
          component={AdminRoles(ProgramaVentaProductoCodificado, [
            Roles.MIS_VENTA_PRODUCTO_CODIFICADO
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/registrar-mis-productos/:idVenta`}
          component={AdminRoles(ProgramaVentaProductoCodificadoEdit, [
            Roles.VENTA_PRODUCTO_CODIFICADO_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/catalogo-beneficios`}
          component={ProgramaCatalogoBeneficio}
        />
        <LayoutUI
          track
          exact
          path={`/beneficio/:idBeneficio`}
          component={ProgramaBeneficio}
        />
        <LayoutUI
          track
          exact
          path={`/solicitud-beneficio/:idSolicitud`}
          component={Solicitud}
        />
        <LayoutUI
          track
          exact
          path={`/mi-solicitud/:idSolicitud`}
          component={Solicitud}
        />
        <LayoutUI
          track
          exact
          path={`/solicitudes-beneficios`}
          component={AdminSolicitudesBeneficios}
        />
        <LayoutUI
          track
          exact
          path={`/mis-solicitudes`}
          component={AdminSolicitudesBeneficios}
        />
        <LayoutUI
          track
          exact
          path="/acciones"
          component={AdminRoles(AdminAccion, [Roles.ACCION_R])}
        />
        <LayoutUI
          track
          exact
          path="/logs"
          component={AdminRoles(ProgramaLog, [Roles.LOG_R])}
        />
        <LayoutUI
          track
          exact
          path={`/logs/:idLog/view`}
          component={AdminRoles(ProgramaLogView, [Roles.LOG_R])}
        />
        <LayoutUI
          track
          exact
          path="/meds"
          component={AdminRoles(UnileverMed, [Roles.MED_R])}
        />
        <LayoutUI
          track
          exact
          path={`/meds/:codigo/variables`}
          component={AdminRoles(UnileverMedVariables, [
            Roles.MED_R,
            Roles.MED_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/meds/:codigo/variables/:idCampana`}
          component={AdminRoles(UnileverMedVariablesEdit, [
            Roles.MED_R,
            Roles.MED_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/meds/:codigo/preventistas`}
          component={AdminRoles(UnileverMedPreventistas, [
            Roles.PREVENTISTA_PORCENTAJE_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/meds/:codigo"
          component={AdminRoles(UnileverMedEdit, [Roles.MED_W])}
        />
        <LayoutUI
          track
          exact
          path={`/meds/:codigo/view`}
          component={AdminRoles(UnileverMedEdit, [Roles.MED_R])}
        />
        <LayoutUI
          track
          exact
          path={`/meds/:codigo/:edit`}
          component={AdminRoles(UnileverMedEdit, [Roles.MED_R, Roles.MED_W])}
        />
        <LayoutUI
          track
          exact
          path="/mis-meds"
          component={AdminRoles(UnileverMed, [Roles.MIS_MEDS_R])}
        />
        <LayoutUI
          track
          exact
          path="/meds-performances"
          component={AdminRoles(UnileverPerformanceEDD, [
            Roles.MI_INFORME_PERFORMANCE_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/mis-meds/:codigo/variables`}
          component={AdminRoles(UnileverMedVariables, [
            Roles.MIS_MEDS_R,
            Roles.MED_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/mis-meds/:codigo/variables/:idCampana`}
          component={AdminRoles(UnileverMedVariablesEdit, [
            Roles.MIS_MEDS_R,
            Roles.MED_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/mis-meds/:codigo/preventistas`}
          component={AdminRoles(UnileverMedPreventistas, [
            Roles.PREVENTISTA_PORCENTAJE_R
          ])}
        />
        <LayoutUI
          track
          exact
          path="/mis-meds/:codigo"
          component={AdminRoles(UnileverMedEdit, [Roles.MED_W])}
        />
        <LayoutUI
          track
          exact
          path={`/mis-meds/:codigo/view`}
          component={AdminRoles(UnileverMedEdit, [Roles.MIS_MEDS_R])}
        />
        <LayoutUI
          track
          exact
          path={`/mis-meds/:codigo/:edit`}
          component={AdminRoles(UnileverMedEdit, [
            Roles.MIS_MEDS_R,
            Roles.MED_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/targets-preventistas`}
          component={AdminRoles(UnileverTargets, [Roles.TARGET_USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/targets-preventistas-mis-meds`}
          component={AdminRoles(UnileverTargets, [Roles.TARGET_USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/targets-mis-preventistas`}
          component={AdminRoles(UnileverCampanasMeds, [Roles.TARGET_USUARIO_R])}
        />
        <LayoutUI
          track
          exact
          path={`/targets-mis-preventistas/:idCampana`}
          component={AdminRoles(UnileverCampanaTargets, [
            Roles.TARGET_USUARIO_R,
            Roles.TARGET_USUARIO_W
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/porcentajes-mis-preventistas`}
          component={AdminRoles(UnileverMedPreventistas, [
            Roles.PREVENTISTA_PORCENTAJE_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/performance-campanas`}
          component={AdminRoles(PerformanceCampanas, [
            Roles.CAMPANA_PERFORMANCE_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/mi-performance-campanas`}
          component={AdminRoles(PerformanceCampanas, [
            Roles.CAMPANA_MI_PERFORMANCE_R
          ])}
        />
        <LayoutUI
          track
          exact
          path={`/links-tracking`}
          component={AdminRoles(AdminLinksTracking, [Roles.LINKS_TRACKING_R])}
        />
        <LayoutUI
          track
          exact
          path={`/moderacion`}
          component={AdminRoles(AdminModeracion, [Roles.MODERACION_R])}
        />
        <LayoutUI
          track
          exact
          path={`/moderacion-factura-por-puntos`}
          component={AdminRoles(AdminModeracionFacturaPorPuntos, [
            Roles.MODERACION_FACTURA_POR_PUNTOS_R
          ])}
        />
        <Access track exact path="/signin" component={Signin} />
        <Access track exact path="/updatePassword" component={UpdatePassword} />
        <Access track exact path="/restoreUser" component={RestoreUser} />
        <Access track exact path="/signup" component={Signup} />
        <Access
          track
          exact
          path="/restorePassword"
          component={RestorePassword}
        />
        <Access
          track
          exact
          path="/email-verificado"
          component={EmailVerificado}
        />
        <LayoutBO
          exact
          path="/admin/dashboard"
          component={AdminRoles(AdminDashboard, [Roles.PROGRAMA_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/template-builder-2/template`}
          component={Template}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/template-builder-2/layout`}
          component={Layout}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/template-builder-2/landing`}
          component={LandingEditor}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/template-builder-2/destacados`}
          component={Destacados}
        />
        <LayoutBO
          exact
          path={`/admin/programa`}
          component={AdminRoles(
            AdminPrograma,
            [],
            [Roles.PROGRAMA_R, Roles.PROGRAMA_LISTA]
          )}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id`}
          component={AdminRoles(ProgramaEdit, [
            Roles.PROGRAMA_R,
            Roles.PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/links-tracking/edit`}
          component={AdminRoles(LinksTrackingEdit, [Roles.LINKS_TRACKING_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/api-tokens/programas-terceros`}
          component={AdminRoles(AdminProgramasTerceros, [Roles.API_TOKEN_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/api-tokens/programas-terceros/new`}
          component={AdminRoles(AdminProgramasTercerosEdit, [
            Roles.API_TOKEN_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/api-tokens/programas-terceros/:idProgramaTercero/edit`}
          component={AdminRoles(AdminProgramasTercerosEdit, [
            Roles.API_TOKEN_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/gestion`}
          component={AdminRoles(ProgramaGestion, [Roles.PROGRAMA_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/legales`}
          component={AdminRoles(ProgramaLegales, [
            Roles.PROGRAMA_R,
            Roles.PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/segmentos`}
          component={AdminRoles(ProgramaSegmentos, [
            Roles.PROGRAMA_R,
            Roles.PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/notificaciones`}
          component={AdminRoles(ProgramaNotificaciones, [
            Roles.PROGRAMA_R,
            Roles.PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/notificaciones/:idNotificacion/edit`}
          component={AdminRoles(ProgramaNotificacionesEdit, [Roles.PROGRAMA_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/perfil`}
          component={AdminRoles(ProgramaPerfil, [Roles.DEFINICION_PERFIL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/canjes/:idCanje`}
          component={AdminRoles(AdminCanjeDetail, [
            Roles.CANJE_R,
            Roles.CANJE_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/canjes/:idCanje/:view`}
          component={AdminRoles(AdminCanjeDetail, [Roles.CANJE_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogo`}
          component={AdminRoles(ProgramaCatalogo, [Roles.PRODUCTO_CATALOGO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogo/new`}
          component={AdminRoles(ProgramaGenerarEleccionProducto, [
            Roles.PRODUCTO_CATALOGO_R,
            Roles.PRODUCTO_CATALOGO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogo/producto/:idProducto/eleccion/:idCatalogo`}
          component={AdminRoles(ProgramaCatalogoEdit, [
            Roles.PRODUCTO_CATALOGO_R,
            Roles.PRODUCTO_CATALOGO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogo/producto/:idProducto/eleccion/:idCatalogo/:edit`}
          component={AdminRoles(ProgramaCatalogoEdit, [
            Roles.PRODUCTO_CATALOGO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/usuario`}
          component={AdminRoles(AdminUsuario, [Roles.USUARIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/cc-usuarios`}
          component={AdminRoles(AdminUsuariosCuentaCorriente, [
            Roles.MOVIMIENTO_USUARIO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/cc-usuarios/new`}
          component={AdminRoles(AdminNewUsuariosCuentaCorriente, [
            Roles.ASIGNAR_PUNTOS_USUARIO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/usuario/:idUsuario/cc-usuario`}
          component={AdminRoles(AdminUsuariosCuentaCorriente, [
            Roles.USUARIO_R,
            Roles.MOVIMIENTO_USUARIO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/usuario/:idUsuario/cc-usuario/new`}
          component={AdminRoles(AdminNewUsuariosCuentaCorriente, [
            Roles.ASIGNAR_PUNTOS_USUARIO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/usuario/:idUsuario`}
          component={AdminRoles(AdminUsuarioEdit, [Roles.USUARIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/usuario/:idUsuario/edit/perfil`}
          component={AdminRoles(AdminUsuarioPerfilEdit, [Roles.USUARIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/usuario/:idUsuario/:edit`}
          component={AdminRoles(AdminUsuarioEdit, [Roles.USUARIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/cuenta_corriente`}
          component={AdminRoles(ProgramaCuentaCorriente, [
            Roles.MOVIMIENTO_PROGRAMA_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/cuenta_corriente/new`}
          component={AdminRoles(ProgramaCuentaCorrienteEdit, [
            Roles.ASIGNAR_PUNTOS_PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/canjes`}
          component={AdminRoles(AdminCanje, [Roles.CANJE_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/idioma`}
          component={AdminRoles(Idioma, [Roles.IDIOMA_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/idioma/:idIdioma`}
          component={AdminRoles(ProgramaIdiomaEdit, [
            Roles.IDIOMA_R,
            Roles.IDIOMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/idioma/:idIdioma/:edit`}
          component={AdminRoles(ProgramaIdiomaEdit, [
            Roles.IDIOMA_R,
            Roles.IDIOMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/roles`}
          component={AdminRoles(ProgramaRoles, [Roles.ROL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/roles/:idRol`}
          component={AdminRoles(ProgramaRolesEdit, [Roles.ROL_R, Roles.ROL_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/acciones`}
          component={AdminRoles(AdminAccion, [Roles.ACCION_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/acciones/:new`}
          component={AdminAccionEdit}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/acciones/:idAccion/:edit`}
          component={AdminRoles(AdminAccionEdit, [Roles.ACCION_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/jerarquia`}
          component={AdminRoles(AdminFormularioJerarquiaEdit, [
            Roles.FORMULARIO_RECONOCIMIENTO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/reconocimiento`}
          component={AdminRoles(AdminFormularioReconocimientoEdit, [
            Roles.FORMULARIO_RECONOCIMIENTO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/campanas-unilever`}
          component={AdminRoles(UnileverAdminCampana, [Roles.CAMPANA_OP_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/campanas-unilever/:idCampana/targets`}
          component={AdminRoles(UnileverAdminTargets, [
            Roles.TARGET_USUARIO_W,
            Roles.TARGET_USUARIO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/campanas-unilever/:idCampana`}
          component={AdminRoles(UnileverAdminCampanaEdit, [Roles.CAMPANA_OP_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/campanas-unilever/:idCampana/:edit`}
          component={AdminRoles(UnileverAdminCampanaEdit, [Roles.CAMPANA_OP_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/grupos-sku`}
          component={AdminRoles(UnileverAdminGruposSKU, [Roles.CAMPANA_OP_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/productos-retail`}
          component={AdminRoles(AdminProductoRetail, [Roles.PRODUCTO_RETAIL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/productos-retail/:codigoProducto`}
          component={AdminRoles(AdminProductoRetailEdit, [
            Roles.PRODUCTO_RETAIL_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/productos-retail/:codigoProducto/edit`}
          component={AdminRoles(AdminProductoRetailEdit, [
            Roles.PRODUCTO_RETAIL_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/canales-retail`}
          component={AdminRoles(AdminCanalRetail, [Roles.CANAL_RETAIL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/canales-retail/:codigoCanal`}
          component={AdminRoles(AdminCanalRetailEdit, [Roles.CANAL_RETAIL_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/canales-retail/:codigoCanal/edit`}
          component={AdminRoles(AdminCanalRetailEdit, [Roles.CANAL_RETAIL_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/clientes-retail`}
          component={AdminRoles(AdminClienteRetail, [Roles.CLIENTE_RETAIL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/clientes-retail/:documento`}
          component={AdminRoles(AdminClienteRetailEdit, [
            Roles.CLIENTE_RETAIL_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/clientes-retail/:documento/edit`}
          component={AdminRoles(AdminClienteRetailEdit, [
            Roles.CLIENTE_RETAIL_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/ventas-retail`}
          component={AdminRoles(AdminVentaRetail, [Roles.VENTA_RETAIL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/ventas-retail/:idVenta/view`}
          component={AdminRoles(ProgramaVentaRetailEdit, [
            Roles.VENTA_RETAIL_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/productos-codificados`}
          component={AdminRoles(AdminProductoCodificado, [
            Roles.PRODUCTO_CODIFICADO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/productos-codificados/:codigoProducto`}
          component={AdminRoles(AdminProductoCodificadoEdit, [
            Roles.PRODUCTO_CODIFICADO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/productos-codificados/:codigoProducto/edit`}
          component={AdminRoles(AdminProductoCodificadoEdit, [
            Roles.PRODUCTO_CODIFICADO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/ventas-productos-codificados`}
          component={AdminRoles(AdminVentaProductoCodificado, [
            Roles.VENTA_PRODUCTO_CODIFICADO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/beneficios/:idBeneficio/sucursales-beneficio`}
          component={AdminRoles(AdminSucursalesBeneficio, [
            Roles.SUCURSAL_BENEFICIO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/beneficios/:idBeneficio/sucursales-beneficio/:idSucursal`}
          component={AdminRoles(AdminSucursalBeneficioEdit, [
            Roles.SUCURSAL_BENEFICIO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/beneficios/:idBeneficio/sucursales-beneficio/:idSucursal/view`}
          component={AdminRoles(AdminSucursalBeneficioEdit, [
            Roles.SUCURSAL_BENEFICIO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/beneficios/:idBeneficio/sucursales-beneficio/:idSucursal/:edit`}
          component={AdminRoles(AdminSucursalBeneficioEdit, [
            Roles.SUCURSAL_BENEFICIO_R,
            Roles.SUCURSAL_BENEFICIO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/beneficios`}
          component={AdminRoles(AdminBeneficio, [Roles.BENEFICIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/beneficios/:idBeneficio`}
          component={AdminRoles(AdminBeneficioEdit, [Roles.BENEFICIO_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/beneficios/:idBeneficio/view`}
          component={AdminRoles(AdminBeneficioEdit, [Roles.BENEFICIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/beneficios/:idBeneficio/:edit`}
          component={AdminRoles(AdminBeneficioEdit, [
            Roles.BENEFICIO_R,
            Roles.BENEFICIO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/solicitudes-beneficios`}
          component={AdminRoles(AdminSolicitudesBeneficios, [
            Roles.SOLICITUD_BENEFICIO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds/:codigo/variables`}
          component={AdminRoles(UnileverMedVariables, [
            Roles.MED_R,
            Roles.MED_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds/:codigo/variables/:idCampana`}
          component={AdminRoles(UnileverMedVariablesEdit, [
            Roles.MED_R,
            Roles.MED_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds/:codigo/preventistas`}
          component={AdminRoles(UnileverMedPreventistas, [
            Roles.PREVENTISTA_PORCENTAJE_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds/notificaciones`}
          component={AdminRoles(UnileverNotificaciones, [
            Roles.MED_R,
            Roles.PROGRAMA_R,
            Roles.PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds/notificaciones/:idNotificacion/edit`}
          component={AdminRoles(UnileverNotificacionesEdit, [
            Roles.MED_R,
            Roles.PROGRAMA_R,
            Roles.PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds`}
          component={AdminRoles(UnileverMed, [Roles.MED_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds/:codigo`}
          component={AdminRoles(UnileverMedEdit, [Roles.MED_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds/:codigo/view`}
          component={AdminRoles(UnileverMedEdit, [Roles.MED_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/meds/:codigo/:edit`}
          component={AdminRoles(UnileverMedEdit, [Roles.MED_R, Roles.MED_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/campanas`}
          component={AdminRoles(AdminCampanas, [Roles.CAMPANA_R])}
        />
        {/* MODERACION */}
        <LayoutBO
          exact
          path={`/admin/programa/:id/moderacion`}
          component={AdminRoles(AdminModeracion, [Roles.MODERACION_R])}
        />
        {/* FACTURAS */}
        <LayoutBO
          exact
          path={`/admin/programa/:id/carga-facturas`}
          component={AdminRoles(AdminCargaFacturas, [Roles.FACTURAS_R])}
        />
        {/* FACTURA POR PUNTOS */}
        <LayoutBO
          exact
          path={`/admin/programa/:id/factura-por-puntos`}
          component={AdminRoles(AdminFacturaPorPuntos, [
            Roles.FACTURAS_POR_PUNTOS_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/parametros-factura-por-puntos`}
          component={AdminRoles(AdminFacturaPorPuntosParametros, [
            Roles.FACTURAS_POR_PUNTOS_R
          ])}
        />
        {/* MODERACION FACTURA POR PUNTOS*/}
        <LayoutBO
          exact
          path={`/admin/programa/:id/moderacion-factura-por-puntos`}
          component={AdminRoles(AdminModeracionFacturaPorPuntos, [
            Roles.MODERACION_FACTURA_POR_PUNTOS_R
          ])}
        />
        {/* CARGA DE CODIGOS */}
        <LayoutBO
          exact
          path={`/admin/programa/:id/carga-de-codigos`}
          component={AdminRoles(AdminCargaDeCodigos, [Roles.CARGACODIGOS_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/parametros-banner-carga-de-codigos`}
          component={AdminRoles(AdminCargaDeCodigosParametrosBanner, [
            Roles.CAMPANA_PARAMETROS_R
          ])}
        />
        {/* en el front hay una ruta "/admin/programa/:id/ventas-codigos-cargados" que lleva a una tabla que se armo 
        para renderizar los productos canjeados u obtenidos, en front falta el boton que lleve a esa ruta */}
        <LayoutBO
          exact
          path={`/admin/programa/:id/ventas-codigos-cargados`}
          component={AdminRoles(AdminVentaCodigoCargado, [
            Roles.VENTA_CARGA_CODIGOS_R
          ])}
        />
        {/* solapa para "Catalogo de Dcanje" */}
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogoCodigos`}
          component={AdminRoles(AdminCatalogoCodigos, [
            Roles.CATALOGO_CODIGO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogoCodigos/:idBeneficio`}
          component={AdminRoles(AdminCatalogoCodigosEdit, [
            Roles.CATALOGO_CODIGO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogoCodigos/:idBeneficio/view`}
          component={AdminRoles(AdminCatalogoCodigosEdit, [
            Roles.CATALOGO_CODIGO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogoCodigos/:idBeneficio/:edit`}
          component={AdminRoles(AdminCatalogoCodigosEdit, [
            Roles.CATALOGO_CODIGO_R,
            Roles.CATALOGO_CODIGO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogoCodigos/:idBeneficio/sucursales-beneficio`}
          component={AdminRoles(AdminSucursalesCatalogoCodigos, [
            Roles.SUCURSAL_CATALOGO_CODIGO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/catalogoCodigos/:idBeneficio/sucursales-beneficio/:idSucursal`}
          component={AdminRoles(AdminSucursalCatalogoCodigosEdit, [
            Roles.SUCURSAL_CATALOGO_CODIGO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/solicitudes-catalogo-codigos`}
          component={AdminRoles(AdminSolicitudesCatalogoCodigos, [
            Roles.SOLICITUD_CATALOGO_CODIGO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/campanas/:idCampana/objetivos`}
          component={AdminRoles(AdminCampanaObjetivos, [
            Roles.CAMPANA_R,
            Roles.CAMPANA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/campanas/:idCampana`}
          component={AdminRoles(AdminCampanaEdit, [
            Roles.CAMPANA_R,
            Roles.CAMPANA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/campanas/new`}
          component={AdminRoles(AdminCampanaEdit, [Roles.CAMPANA_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/grupos-productos-campanas`}
          component={AdminRoles(AdminCampanasGruposProductos, [
            Roles.CAMPANA_PRODUCTOS_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/parametros-campanas`}
          component={AdminRoles(AdminCampanasParametros, [
            Roles.CAMPANA_PARAMETROS_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/targets-campanas`}
          component={AdminRoles(AdminCampanasTargets, [Roles.CAMPANA_TARGET_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/links-tracking`}
          component={AdminRoles(AdminLinksTracking, [Roles.LINKS_TRACKING_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/sesiones-tracking`}
          component={AdminRoles(AdminSesionesTracking, [
            Roles.SESSION_TRACKING_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/api-tokens`}
          component={AdminRoles(AdminApiToken, [Roles.API_TOKEN_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/api-tokens/:idToken`}
          component={AdminRoles(AdminApiTokenEdit, [Roles.API_TOKEN_W])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/api-tokens/:idToken/:edit`}
          component={AdminRoles(AdminApiTokenEdit, [Roles.API_TOKEN_R])}
        />
        <LayoutBO
          exact
          path={`/admin/programa/:id/:edit`}
          component={AdminRoles(ProgramaEdit, [
            Roles.PROGRAMA_R,
            Roles.PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/producto`}
          component={AdminRoles(AdminProducto, [Roles.PRODUCTO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/producto/:idProducto`}
          component={AdminRoles(ProductoEdit, [
            Roles.PRODUCTO_R,
            Roles.PRODUCTO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/producto/:idProducto/:edit`}
          component={AdminRoles(ProductoEdit, [Roles.PRODUCTO_R])}
        />
        <LayoutBO
          exact
          path="/admin/reportes"
          component={AdminRoles(ReportsTable, [Roles.REPORTE_R])}
        />
        <LayoutBO
          exact
          path="/admin/reportes/:idReport"
          component={AdminRoles(ReportsDetail, [Roles.REPORTE_R])}
        />
        <LayoutBO
          exact
          path={`/admin/roles`}
          component={AdminRoles(ProgramaRoles, [Roles.ROL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/roles/:idRol`}
          component={AdminRoles(ProgramaRolesEdit, [Roles.ROL_R, Roles.ROL_W])}
        />
        <LayoutBO
          exact
          path={`/admin/logs`}
          component={AdminRoles(ProgramaLog, [Roles.LOG_R])}
        />
        <LayoutBO
          exact
          path={`/admin/logs/:idLog/view`}
          component={AdminRoles(ProgramaLogView, [Roles.LOG_R])}
        />
        <LayoutBO
          exact
          path={`/admin/usuario`}
          component={AdminRoles(AdminUsuario, [Roles.USUARIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/usuario/:idUsuario`}
          component={AdminRoles(AdminUsuarioEdit, [Roles.USUARIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/usuario/:idUsuario/cc-usuario`}
          component={AdminRoles(AdminUsuariosCuentaCorriente, [
            Roles.MOVIMIENTO_USUARIO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/usuario/:idUsuario/cc-usuario/new`}
          component={AdminRoles(AdminNewUsuariosCuentaCorriente, [
            Roles.ASIGNAR_PUNTOS_USUARIO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/usuario/:idUsuario/:edit`}
          component={AdminRoles(AdminUsuarioEdit, [Roles.USUARIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/usuario/:idUsuario/edit/perfil`}
          component={AdminRoles(AdminUsuarioPerfilEdit, [Roles.USUARIO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/cuenta_corriente`}
          component={AdminRoles(AdminCuentaCorriente, [
            Roles.MOVIMIENTO_PROGRAMA_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/cuenta_corriente/new`}
          component={AdminRoles(ProgramaCuentaCorrienteEdit, [
            Roles.ASIGNAR_PUNTOS_PROGRAMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/proveedor`}
          component={AdminRoles(AdminProveedor, [Roles.PROVEEDOR_R])}
        />
        <LayoutBO
          exact
          path={`/admin/proveedor/:idProveedor`}
          component={AdminRoles(ProveedorEdit, [
            Roles.PROVEEDOR_R,
            Roles.PROVEEDOR_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/producto/:idProducto/:idProveedor/sucursal`}
          component={AdminRoles(ProductoSucursal, [Roles.PRODUCTO_R])}
        />
        <LayoutBO
          exact
          path={`/admin/producto/:idProducto/:idProveedor/sucursal/:idSucursal`}
          component={AdminRoles(ProductoSucursalEdit, [
            Roles.PRODUCTO_R,
            Roles.PRODUCTO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/producto/:idProducto/:idProveedor/sucursal/:idSucursal/view`}
          component={AdminRoles(ProductoSucursalEdit, [Roles.PROVEEDOR_R])}
        />
        <LayoutBO
          exact
          path={`/admin/producto/:idProducto/:idProveedor/sucursal/:idSucursal/:edit`}
          component={AdminRoles(ProductoSucursalEdit, [
            Roles.PRODUCTO_R,
            Roles.PRODUCTO_W
          ])}
        />
        z
        <LayoutBO
          exact
          path={`/admin/proveedor/:idProveedor/sucursal`}
          component={AdminRoles(ProveedorSucursal, [Roles.PROVEEDOR_R])}
        />
        <LayoutBO
          exact
          path={`/admin/proveedor/:idProveedor/sucursal/:idSucursal`}
          component={AdminRoles(ProveedorSucursalEdit, [
            Roles.PROVEEDOR_R,
            Roles.PROVEEDOR_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/proveedor/:idProveedor/view`}
          component={AdminRoles(ProveedorEdit, [Roles.PROVEEDOR_R])}
        />
        <LayoutBO
          exact
          path={`/admin/proveedor/:idProveedor/:edit`}
          component={AdminRoles(ProveedorEdit, [
            Roles.PROVEEDOR_R,
            Roles.PROVEEDOR_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/proveedor/:idProveedor/sucursal/:idSucursal/view`}
          component={AdminRoles(ProveedorSucursalEdit, [Roles.PROVEEDOR_R])}
        />
        <LayoutBO
          exact
          path={`/admin/proveedor/:idProveedor/sucursal/:idSucursal/:edit`}
          component={AdminRoles(ProveedorSucursalEdit, [
            Roles.PROVEEDOR_R,
            Roles.PROVEEDOR_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/cc-usuarios`}
          component={AdminRoles(AdminUsuariosCuentaCorriente, [
            Roles.MOVIMIENTO_USUARIO_R
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/cc-usuarios/new`}
          component={AdminRoles(AdminNewUsuariosCuentaCorriente, [
            Roles.ASIGNAR_PUNTOS_USUARIO_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/canjes`}
          component={AdminRoles(AdminCanje, [Roles.CANJE_R])}
        />
        <LayoutBO
          exact
          path={`/admin/canjes/:idCanje`}
          component={AdminRoles(AdminCanjeDetail, [
            Roles.CANJE_R,
            Roles.CANJE_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/canjes/:idCanje/:view`}
          component={AdminRoles(AdminCanjeDetail, [Roles.CANJE_R])}
        />
        <LayoutBO
          exact
          path={`/admin/facturas`}
          component={AdminRoles(AdminFacturas, [Roles.FACTURA_R])}
        />
        <LayoutBO
          exact
          path={`/admin/facturas/new`}
          component={AdminRoles(AdminFacturasNew, [
            Roles.FACTURA_R,
            Roles.FACTURA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/facturas/:id`}
          component={AdminRoles(AdminFacturasDetail, [
            Roles.FACTURA_R,
            Roles.FACTURA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/lotes`}
          component={AdminRoles(AdminLotes, [Roles.LOTE_R])}
        />
        <LayoutBO
          exact
          path={`/admin/lotes/:id`}
          component={AdminRoles(AdminLotesDetail, [Roles.LOTE_R, Roles.LOTE_W])}
        />
        <LayoutBO
          exact
          path={`/admin/acciones`}
          component={AdminRoles(AdminAccion, [Roles.ACCION_R])}
        />
        <LayoutBO
          exact
          path={`/admin/acciones/:new`}
          component={AdminAccionEdit}
        />
        <LayoutBO
          exact
          path={`/admin/acciones/:idAccion/:edit`}
          component={AdminRoles(AdminAccionEdit, [Roles.ACCION_R])}
        />
        <LayoutBO
          exact
          path={`/admin/idiomas`}
          component={AdminRoles(AdminIdioma, [Roles.IDIOMA_R])}
        />
        <LayoutBO
          exact
          path={`/admin/idiomas/new`}
          component={AdminRoles(AdminIdiomaNew, [
            Roles.IDIOMA_R,
            Roles.IDIOMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/idiomas/:idIdioma/edit`}
          component={AdminRoles(AdminIdiomaNew, [
            Roles.IDIOMA_R,
            Roles.IDIOMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/idiomas/:idIdioma/gestion`}
          component={AdminRoles(AdminIdiomaEdit, [
            Roles.IDIOMA_R,
            Roles.IDIOMA_W
          ])}
        />
        <LayoutBO
          exact
          path={`/admin/emails`}
          component={AdminRoles(AdminEmails, [Roles.HISTORIAL_EMAIL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/emails/:idEmail/:edit`}
          component={AdminRoles(EmailEdit, [Roles.HISTORIAL_EMAIL_R])}
        />
        <LayoutBO
          exact
          path={`/admin/cln/reportes`}
          component={AdminRoles(AdminReportesCLN, [Roles.CLN_REPORTE_R])}
        />
        <LayoutBO
          exact
          path={`/admin/cln/reportes/gestion`}
          component={AdminRoles(AdminGestionReportesCLN, [Roles.CLN_REPORTE_W])}
        />
        <LayoutBO
          exact
          path={`/admin/canje-terceros`}
          component={AdminRoles(AdminCanjeTerceros, [Roles.CANJE_TERCEROS_W])}
        />
        <LayoutUI exact path="/error" component={Error} />
        <LayoutUI exact path="/ya-participo" component={AccionYaParticipo} />
        {/* <Route component={<LayoutUI component={Error} />} /> */}
        <Route render={props => <LayoutUI component={Error} />} />
      </Switch>
    </Router>
  );
};

export default Routes;
