import React from "react";
import { getField } from "../helpers/getField";
import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from "@material-ui/core";
import { _t } from "../../../utils/TranslationService";
import { numberWithCommas } from "js/utils";
import { lowerCase } from "lodash";

export const DialogDatosRequeridos = ({
  classes,
  datosRequeridosFields,
  datosRequeridosValues,
  handleChangeDatoRequerido,
  onCanje,
  onClose,
  openDialog,
  eleccionProducto,
  cantidad,
  setDatosRequeridosCompleted,
  statusCanje
}) => {
  const handleValidarDatosRequeridos = async () => {
    const datosCompletos = Object.values(datosRequeridosValues).every(
      value => value !== null && value !== ""
    );
    if (datosCompletos) {
      setDatosRequeridosCompleted();
      onClose();
    } else {
      alert("Por favor, completa todos los campos requeridos.");
    }
  };

  const producto = eleccionProducto ? eleccionProducto.producto : null;

  return (
    <Dialog
      fullScreen={false}
      open={openDialog}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5">
          {" "}
          {producto ? producto.nombre : ""}{" "}
        </Typography>
        <Typography variant="h6" color="primary" className={classes.points}>
          {eleccionProducto ? `${eleccionProducto.puntos} PUNTOS` : ""}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {producto ? (
            <Typography variant="subtitle1" component="h2">
              {_t("Estás por canjear")}
              <strong>
                {" "}
                {numberWithCommas(cantidad)} {producto.nombre}{" "}
              </strong>
              {lowerCase(_t("Utilizando"))}
              <strong>
                {" "}
                {numberWithCommas(cantidad * eleccionProducto.puntos)}{" "}
                {_t("Puntos").toLowerCase()}
              </strong>
            </Typography>
          ) : (
            ""
          )}
        </DialogContentText>
        {datosRequeridosFields.length > 0 && (
          <Typography variant="h6">Datos requeridos: </Typography>
        )}
        <Grid container spacing={16}>
          {datosRequeridosFields.length > 0 &&
            datosRequeridosFields.map(
              ({ key = "sucursal", nombre = "Sucursal" }) =>
                getField({
                  key,
                  nombre: _t(nombre),
                  datosRequeridosValues,
                  handleChangeDatoRequerido
                })
            )}
          {producto &&
            producto.tipoProducto === "FISICO" && (
              <Grid item xs={12}>
                <TextField
                  id={"observaciones"}
                  type="text"
                  key={"observaciones"}
                  label={"Observaciones"}
                  value={datosRequeridosValues["observaciones"]}
                  onChange={handleChangeDatoRequerido("observaciones")}
                  margin="normal"
                />
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end" spacing={8}>
          <Grid xs={4} md={3}>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              style={{ width: "90%" }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid xs={4} md={3}>
            <Button
              color="primary"
              onClick={onCanje}
              variant="contained"
              disabled={statusCanje}
              style={{ width: "90%" }}
            >
              {statusCanje ? <CircularProgress size={20} /> : "Confirmar"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
